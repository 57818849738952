<template>
	<b-modal
		id="QuizModal"
		:visible="modalVisibility(modalId)"
		:title="`${textCreateMode(createMode)} Quiz`"
		@show="onShow()"
		@hidden="resetModal()"
		@ok="onOk"
		:size="modalSize"
		hide-footer
		no-close-on-backdrop
		ok-title="Done"
	>
		<quiz-list-form
			:create-mode="createMode"
			:selected-quiz="selectedQuiz"
			:modal-size="modalSize"
		></quiz-list-form>
	</b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
// import { email, required, sameAs } from "vuelidate/lib/validators";

import QuizListForm from "@/view/pages/globaladmin/quiz/forms/QuizListForm.vue";
export default {
	components: { QuizListForm },
	props: {
		createMode: { type: Boolean, default: false },
	},
	data() {
		return {
			selectedQuiz: null,
			modalSize: "xl",
			readOnly: true,
			modalId: "QuizModal",
			isBusy: true,
		};
	},
	methods: {
		textCreateMode(mode) {
			if (mode) {
				return "Create";
			} else {
				return "Update";
			}
		},
		toggleQuestionBank() {
			this.modalSize = this.modalSize === "md" ? "xl" : "md";
		},
		onShow() {
			this.selectedQuiz = JSON.parse(
				JSON.stringify(this.$parent.selectedQuiz)
			);
		},
		onOk(bvModalEvent) {
			console.log(bvModalEvent);
			bvModalEvent.preventDefault();

			this.$store.dispatch(SET_MODAL_STATE, null);
		},
		resetModal() {
			this.$store.dispatch(SET_MODAL_STATE, null);
		},
	},
};
</script>
