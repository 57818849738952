<template>
	<div>
		<b-row>
			<b-col>
				<b-row>
					<b-col>
						<base-input
							type="text"
							v-model="form.quiz_name"
							label="Name"
							:validator="$v.form.quiz_name"
							:messages="localMessages"
						></base-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<base-input
							type="select"
							v-model="form.answer_type"
							label="Question Type"
							placeholder="Please select"
							:options="questionTypeOptions"
							:validator="$v.form.answer_type"
							:messages="localMessages"
						></base-input>
					</b-col>
					<b-col>
						<base-input
							type="select"
							v-model="form.quiz_validation_type"
							label="Validation Type"
							placeholder="Please select"
							:options="quizValidationTypeOptions"
							:validator="$v.form.quiz_validation_type"
							:messages="localMessages"
							:tooltipData="quizValidationTypeTooltip"
						></base-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col
						><base-input
							type="text"
							v-model="form.category"
							label="Category"
							:validator="$v.form.category"
							:messages="localMessages"
						></base-input
					></b-col>
					<b-col
						><base-input
							type="text"
							v-model="form.duration"
							label="Duration"
							:validator="$v.form.duration"
							:messages="localMessages"
						></base-input
					></b-col>
				</b-row>
				<b-row>
					<b-col
						><base-input
							type="textarea"
							v-model="form.description"
							label="Description"
							:validator="$v.form.description"
							:messages="localMessages"
						></base-input
					></b-col>
				</b-row>

				<b-row class="mb-4">
					<b-col>
						<label>Current Question(s)</label>
						<b-table
							:items="QuizQuestionList"
							:fields="currentQuizQuestionFields"
							striped
							bordered
							show-empty
							hover
						></b-table>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<label>Selected Questions</label>
						<div
							class="p-4 bg-secondary"
							v-if="this.reducedQuestionList.length === 0"
						>
							No question selected yet
						</div>
						<template>
							<div
								v-for="questions in form.questions_list"
								class="p-4 border bg-light"
								:key="questions.question_id"
							>
								{{ questions.question_name }}
							</div>
						</template>
					</b-col>
				</b-row>
				<b-row class="mt-6">
					<b-col class="d-flex flex-row-reverse">
						<b-button
							@click="onSubmit"
							class="ml-3"
							variant="primary"
							size="lg"
						>
							Submit
						</b-button>
					</b-col>
				</b-row>
			</b-col>
			<b-col v-if="modalSize === 'xl'">
				<h4>List of Questions</h4>
				<p class="mb-5">
					Select or unselect the questions you wanted to include or
					exclude in this quiz
				</p>

				<b-row class="mb-3">
					<b-col
						><div>
							<b-button
								@click="selectAllRows"
								class="mr-3"
								size="sm"
								>Select All</b-button
							>
							<b-button
								@click="clearSelected"
								class="mr-3"
								size="sm"
								>Clear Selected</b-button
							>
						</div></b-col
					>
					<b-col>
						<template>
							<b-input-group size="sm">
								<b-form-input
									v-model="filter"
									type="search"
									placeholder="Type to Search"
								></b-form-input>

								<b-input-group-append>
									<b-button
										:disabled="!filter"
										@click="filter = ''"
										>Clear</b-button
									>
								</b-input-group-append>
							</b-input-group>
						</template>
					</b-col>
				</b-row>
				<b-table
					sticky-header="500px"
					ref="selectableQuizTable"
					:filter="filter"
					striped
					selectable
					bordered
					empty-text="Please create the questions first"
					show-empty
					hover
					:items="questionBankList"
					:fields="questionListFields"
					@row-selected="onRowSelected"
				></b-table>
			</b-col>
		</b-row>

		<b-row class="mt-6">
			<b-col class="d-flex flex-row-reverse">
				<b-button
					variant="secondary"
					size="lg"
					@click="closeModal('QuestionAnswerModal')"
					>Cancel
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	CREATE_QUIZ,
	GET_ALL_QUIZ_LIST,
	GET_QUESTION_LIST,
	GET_QUIZ_LIST,
	UPDATE_QUIZ,
} from "@/core/services/store/modules/globaladmin/trainingcourse.module";

import SystemHelper from "@/core/services/systemhelper.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
	mixins: [validationMixin],
	props: {
		createMode: { type: Boolean, default: false },
		selectedQuiz: { type: Object },
		modalSize: { tyep: String, default: "" },
	},
	data() {
		return {
			form: {
				description: "",
				category: "",
				duration: "",
				quiz_name: "",
				answer_type: "",
				quiz_validation_type: "",
				questions_list: [],
			},
			currentQuizQuestionFields: [
				{ key: "question_name" },
				{ key: "question_category", label: "Category" },
			],
			questionListFields: [
				{ key: "question_name" },
				{ key: "category" },
				{ label: "Type", key: "question_type" },
			],
			reducedQuestionList: [],
			quizValidationTypeTooltip: {
				icon: "fa fa-info-circle",
				message: `How many question are required to pass?`,
			},
			filter: "",
		};
	},
	validations: {
		form: {
			description: { required },
			category: { required },
			duration: { required },
			quiz_name: { required },
			answer_type: { required },
			quiz_validation_type: { required },
			questions_list: [],
		},
	},
	computed: {
		QuizData() {
			const quizData = this.$store.state.globaladmin_trainingcourse
				.quizData;
			if (typeof quizData === "object" && Object.keys(quizData).length) {
				return quizData;
			} else {
				return {};
			}
		},
		QuizQuestionList() {
			const quiz = this.QuizData;
			if (Object.keys(quiz).length) {
				const questionList = quiz.quiz.questionsList;
				if (Array.isArray(questionList) && questionList.length) {
					return questionList;
				} else {
					return [];
				}
			} else {
				return [];
			}
		},
		questionBankList() {
			const list = this.$store.state.globaladmin_trainingcourse
				.questionList;
			console.log(list);
			if (Array.isArray(list) && list.length) {
				return list;
			} else {
				return [];
			}
		},
		questionTypeOptions() {
			const questionTypeList = [
				{
					name: "Objective",
					code: "objective",
				},
				{
					name: "Subjective",
					code: "subjective",
				},
			];
			let options = [];
			if (questionTypeList.length) {
				questionTypeList.forEach((questionTypeList) => {
					options.push({
						label: questionTypeList.name,
						value: questionTypeList.code,
					});
				});
			}
			return options;
		},
		quizValidationTypeOptions() {
			const quizValidationTypeList = [
				{
					name: "All",
					code: "1",
				},
				{
					name: "Majority",
					code: "2",
				},
				{
					name: "Unevaluated",
					code: "3",
				},
			];
			let options = [];
			if (quizValidationTypeList.length) {
				quizValidationTypeList.forEach((opt) => {
					options.push({
						label: opt.name,
						value: opt.code,
					});
				});
			}
			return options;
		},
	},
	methods: {
		clearSelected() {
			this.$refs.selectableQuizTable.clearSelected();
			this.reducedQuestionList = [];
		},
		selectAllRows() {
			this.$refs.selectableQuizTable.selectAllRows();
		},
		onRowSelected(items) {
			this.form.questions_list = items;

			let x = [];
			items.forEach((item) => {
				x.push(item.question_id);
			});
			this.reducedQuestionList = x;
		},
		selectRow(tableIndex) {
			// Rows are indexed from 0, so the third row is index 2
			this.$refs.selectableQuizTable.selectRow(tableIndex);
		},
		onSubmit() {
			this.$v.form.$touch();

			if (
				this.$v.form.$anyError ||
				this.form.questions_list.length === 0
			) {
				return;
			} else {
				this.form.questions_list = JSON.stringify(
					this.reducedQuestionList
				);
				let payload = {
					...this.form,
				};
				console.log("submit");
				if (!this.createMode) {
					SystemHelper.confirmationDialogHandler(
						{ title: "Confirmation", html: "Update Quiz?" },
						{ action: UPDATE_QUIZ, payload: payload },
						[
							{
								action: GET_ALL_QUIZ_LIST,
								payload: this.defaultPayload,
							},
						],
						this.modalId
					);
				} else {
					//create
					SystemHelper.confirmationDialogHandler(
						{ title: "Confirmation", html: "Create Quiz?" },
						{ action: CREATE_QUIZ, payload: payload },
						[
							{
								action: GET_ALL_QUIZ_LIST,
								payload: this.defaultPayload,
							},
						],
						this.modalId
					);
				}

				// }
			}
		},
	},
	mounted() {
		this.$store.dispatch(GET_QUESTION_LIST, this.defaultPayload);

		if (!this.createMode) {
			this.form = this.selectedQuiz;
			let payload = {
				quiz_id: this.selectedQuiz.id,
			};

			let _this = this;
			this.$store.dispatch(GET_QUIZ_LIST, payload).then((res) => {
				console.log(res);

				let selectedQuestions = res.data.quiz.questionsList;
				console.log(selectedQuestions);
				let questionBankList = this.questionBankList;
				selectedQuestions.forEach((item) => {
					console.log("selectedQuestions", item);
					let index = questionBankList.findIndex(
						(p) => p.question_id === item.question_id
					);
					_this.selectRow(index);
				});
			});
		}
	},
};
</script>
