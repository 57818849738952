<template>
  <div>
    <KTCard title="Quiz List">
      <template v-slot:toolbar>
        <b-button
          @click="(createMode = true), openModal('QuizModal')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Create Quiz
        </b-button>
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="tableItems"
          :fields="fields"
        >
          <template v-slot:cell(quiz_validation_type)="row">
            <template v-if="row.item.quiz_validation_type === 1">ALL</template>
            <template v-if="row.item.quiz_validation_type === 2"
              >MAJORITY</template
            >
            <template v-if="row.item.quiz_validation_type === 3"
              >NOT EVALUATED</template
            >
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button @click="onView(row.item)" variant="primary" size="sm">
                Details
              </b-button>
              <b-button @click="onDelete(row.item)" variant="danger" size="sm">
                Delete
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <quiz-modal :create-mode="createMode"></quiz-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  DELETE_QUIZ,
  GET_ALL_QUIZ_LIST,
} from "@/core/services/store/modules/globaladmin/trainingcourse.module";

import QuizModal from "@/view/pages/globaladmin/quiz/modals/QuizModal";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  components: { QuizModal },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Global Admin" },
      { title: "Quiz" },
    ]);
    this.$store.dispatch(GET_ALL_QUIZ_LIST, this.defaultPayload);
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.globaladmin_trainingcourse.allQuizList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        pagination: true,
        refresh: false,
        filter: true,
      };
    },
    tableItems() {
      const list = this.$store.state.globaladmin_trainingcourse.allQuizList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      createMode: false,
      selectedQuiz: null,
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "id",
        },
        {
          key: "quiz_name",
        },
        {
          key: "answer_type",
        },
        {
          key: "quiz_validation_type",
        },
        {
          key: "actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onView(rowItem) {
      this.createMode = false;
      console.log(rowItem);
      this.selectedQuiz = rowItem;
      this.openModal("QuizModal");
    },
    onDelete(rowItem) {
      console.log(rowItem);
      this.selectedQuiz = rowItem;

      let payload = {
        quiz_id: rowItem.quiz_id,
      };
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Delete Quiz?" },
        { action: DELETE_QUIZ, payload: payload },
        [{ action: GET_ALL_QUIZ_LIST, payload: this.defaultPayload }],
        null
      );
    },
  },
};
</script>
